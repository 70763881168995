<template>
  <Card class="mb-30">
    <div class="StatSessionChart">
      <div class="StatSessionChart__head">
        <h2>
          {{
            $lng.__(
              'lk_webmaster_main',
              `Distribution by age of a ${this.gender}`
            )
          }}
        </h2>
        <!-- <template v-if="getAgeQuantity != 0">
          <div @click="expand" class="StatSessionChart__expand" >
            <SvgUse :width="22" :height="22" id="expand"></SvgUse>
          </div>
        </template> -->
      </div>

      <div v-if="expanded" class="StatSessionChart__content">
        <canvas
          :id="`wv-chart-${this.gender}`"
          class="StatSessionChart__canvas"
        ></canvas>
      </div>
      <div v-else-if="getAgeQuantity == 0" class="StatSessionChart__content">
        {{
          $lng.__('lk_webmaster_main', `Not enough data to show this report`)
        }}
      </div>
    </div>
  </Card>
</template>

<script>
  import { Chart } from 'chart.js';
  import chartColors from './chartColors.js';

  export default {
    name: 'StatAudienceFiltersChart',
    props: {
      gender: String,
      genderData: Array,
    },
    data() {
      return {
        expanded: false,
        ageRange_male: '',
        ageRange_female: '',

      };
    },
    computed: {
      getAgeQuantity() {
        return collect(this.genderData).count();
      },
      getAge() {
        let dataMap = {};
        let sexData = collect(this.genderData)
          .pluck('client_age')
          .all();

        sexData.forEach(function(ageItem) {
          // dataMap[a] = dataMap[a] + 1 || 1;
          if (dataMap[ageItem] != undefined) {
            ++dataMap[ageItem];
          } else {
            dataMap[ageItem] = 1;
          }
        });

        return dataMap;
      },

      getAgeRange() {
        let ageRange = {};
        let ageQuantityFrom18 = 0;
        let ageQuantityFrom75 = 0;
        
        for (let [key, value] of Object.entries(this.getAge)) {
          if(key < 20) {
            ageQuantityFrom18 += value;
            continue;
          } else if (key >= 75) {            
            ageQuantityFrom75 += value;
            continue;
          }
          continue;
        }

        ageRange[
          `${this.$lng.__('lk_webmaster_main', 'from 18')} ${this.$lng.__(
            'lk_webmaster_main',
            'years'
          )}`
        ] = ageQuantityFrom18;

        for (let index = 20; index < 71; index = index + 5) {
          let ageQuantity = 0;

          ageRange[
            `${this.$lng.__(
              'lk_webmaster_main',
              'from'
            )} ${index} ${this.$lng.__('lk_webmaster_main', 'to')} ${index +
              5} ${this.$lng.__('lk_webmaster_main', 'years')}`
          ] = 0;

          for (let [key, value] of Object.entries(this.getAge)) {
            if (key >= index && key < (index + 5)) {
              ageQuantity = ageQuantity + value;

              ageRange[
                `${this.$lng.__(
                  'lk_webmaster_main',
                  'from'
                )} ${index} ${this.$lng.__('lk_webmaster_main', 'to')} ${index +
                  5} ${this.$lng.__('lk_webmaster_main', 'years')}`
              ] = ageQuantity;

              continue;

            }

            continue;
          }          
        }

        ageRange[
          `${this.$lng.__('lk_webmaster_main', 'from 75')} ${this.$lng.__(
            'lk_webmaster_main',
            'years'
          )}`
        ] = ageQuantityFrom75;

        this[`ageRange_${this.gender}`] = ageRange;

        return ageRange;
      },

      ageCount() {
        let data = [];

        for (let [key, value] of Object.entries(this.getAgeRange)) {         
          if(value > 0 && value != undefined){
            let percent = (value * 100) / this.getAgeQuantity;
            data.push(percent.toFixed(2));
          }
        }

        return data;
      },
      ageNumber() {
        let data = [];
        let index = 0;

        for (let [key, value] of Object.entries(this.getAgeRange)) {
          if(value > 0 && value != undefined){
            data.push(`${key} - ${value} (${this.ageCount[index]}%)`);
            index += 1;
          }            
        }

        return data;
      },

      // getAgeRange() {
      //   let ageLength = 5;
      //   let ageRangeItem = 0;
      //   let ageRange = [];
      //   let ageRangeArrTemp = [];
      //   let ageArr = Object.keys(this.getAge).sort((a, b) => a - b);
      //   let ageLabel = '';

      //   for (let index = 0; index < ageArr.length; index++) {
      //     ageRangeArrTemp.push(ageArr[index]);

      //     ageLabel = `${ageRangeArrTemp[0]} - ${
      //       ageRangeArrTemp[ageRangeArrTemp.length - 1]
      //     } ${this.$lng.__('lk_webmaster_main', 'years')}`;

      //     if (ageRangeArrTemp.length == ageArr.length) {
      //       ageLabel = `${ageRangeArrTemp[0]} ${this.$lng.__(
      //         'lk_webmaster_main',
      //         'years'
      //       )}`;

      //     }

      //     if (index === ageRangeItem + ageLength) {
      //       ageRangeItem += ageLength;
      //       ageRangeArrTemp = [];
      //       ageRange.push(ageLabel);
      //     }
      //   }

      //   return ageRange;
      // },

    },

    methods: {
      makeChart(gender = this.gender) {
        window[`chart_${gender}`] ? window[`chart_${gender}`].destroy() : false;

        if (!document.getElementById(`wv-chart-${gender}`)) return;

        window[`chart_${gender}`] = new Chart(
          document.getElementById(`wv-chart-${gender}`),
          {
            type: 'doughnut',
            data: {
              labels: this.ageNumber,
              datasets: [
                {
                  label: this.ageNumber,
                  data: this.ageCount,
                  backgroundColor: chartColors,
                  hoverOffset: 4,
                },
              ],
            },
            options: {
              responsive: true,
              maintainAspectRatio: false,
              aspectRatio: 1,
              tooltips: {
                callbacks: {
                  label: function(tooltipItem, data) {
                    let label = data.datasets[tooltipItem.datasetIndex].label;
                    return label[tooltipItem.index];
                  }
                }
              },
              legend: {
                position: 'left',
                align: 'start',
              },
              onResize: function() {
                if (window.matchMedia('(max-width: 767px)').matches) {
                  this.legend.position = 'top';
                  this.legend.align = 'center';
                } else {
                  this.legend.position = 'left';
                  this.legend.align = 'start';
                }
              },
            },
          }
        );

        // Responsive chart for mobile
        if (window.matchMedia('(max-width: 767px)').matches) {
          window[`chart_${gender}`].options.legend.position = 'top';
          window[`chart_${gender}`].options.legend.align = 'center';
          let height =
            (window[`chart_${gender}`].data.labels.length / 2) * 22 +
            window[`chart_${gender}`].height +
            'px';
          window[`chart_${gender}`].canvas.parentNode.style.height = height;
          window[`chart_${gender}`].update();
        } else {
          window[`chart_${gender}`].options.legend.position = 'left';
          window[`chart_${gender}`].options.legend.align = 'start';
          window[`chart_${gender}`].update();
        }
      },
      expand() {
        if (this.expanded) {
          this.expanded = false;
        } else {
          this.expanded = true;
          setTimeout(this.makeChart, 200);
        }
      },
    },
    watch: {
      genderData: {
        deep: true,
        handler(val, oldVal) {
          if (collect(val).count() == 0) {
            this.expanded = true;
            this.expand();
          } else {
            this.expanded = false;
            this.expand();
          }
        },
      },
    },
    mounted() {
      //this.is_visible = true
      setTimeout(this.makeChart, 1000);
    },
  };
</script>

<style lang="scss">
  .StatSessionChart {
    &__head {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      h2 {
        padding-bottom: 20px;
      }
    }
    &__expand {
      opacity: 0.5;
      transition: 0.2s opacity ease-in-out;
      cursor: pointer;
      &:hover {
        opacity: 1;
      }
    }
  }
</style>
