<template>
    <div class="StatSessionFilters">

        <DateRangePicker
            :label="$lng.__('lk_webmaster_main', 'Period')"
            ref="picker"
            opens="right"
            :locale-data="{
                        firstDay: 1,
                        format: 'dd.mm.yyyy',
                        daysOfWeek: $lng.__('lk_webmaster_main', 'Sun,Mon,Tue,Wed,Thu,Fri,Sat').split(','),
                        monthNames: $lng.__('lk_webmaster_main', 'Jan,Feb,Mar,Apr,May,Jun,Jul,Aug,Sep,Oct,Nov,Dec').split(','),
                    }"
            :singleDatePicker="false"
            :timePicker="false"
            :showDropdowns="true"
            :autoApply="true"
            :dateRange="filters.dateRange"
            v-model="filters.dateRange"
            @update="setDateRange"
            :linkedCalendars="false">
        </DateRangePicker>

        <FormSelectMulti
            :label="$lng.__('lk_webmaster_main', 'Offer')"
            :placeholder="$lng.__('lk_webmaster_main', 'Choose an offer')"
            v-model:selected="filters.offer"
            :borderless=false
            :multiple=true
            :nested=false
            key="filterByOffer"
            :options=filterByOfferOptions>
        </FormSelectMulti>

        <FormSelectMulti
            :label="$lng.__('lk_webmaster_main', 'Geo')"
            :placeholder="$lng.__('lk_webmaster_main', 'Select country')"
            v-model:selected="filters.country"
            :borderless=false
            :multiple=false
            :nested=false
            key="filterByCountry"
            :options=filterByCountryOptions>
        </FormSelectMulti>

        <FormInput
            label="UTM Content"
            :placeholder="$lng.__('lk_webmaster_main', 'Enter UTM')"
            v-model="filters.byUtmContent"
        ></FormInput>

        <FormInput
            label="UTM Medium"
            :placeholder="$lng.__('lk_webmaster_main', 'Enter UTM')"
            v-model="filters.byUtmMedium"
        ></FormInput>

        <FormInput
            label="UTM Campaign"
            :placeholder="$lng.__('lk_webmaster_main', 'Enter UTM')"
            v-model="filters.byUtmCampaign"
        ></FormInput>

        <FormInput
            label="UTM Term"
            :placeholder="$lng.__('lk_webmaster_main', 'Enter UTM')"
            v-model="filters.byUtmTerm"
        ></FormInput>

        <div class="StatSessionFilters__reset">
            <button
                v-if="isResetFiltersVisible"
                @click="resetFilters()"
                class="wv-btn--green wv-btn--text wv-btn--block">
                {{ $lng.__('lk_webmaster_main', 'Reset') }}
            </button>
        </div>

    </div>
</template>

<script>
export default {
    name: 'StatAudienceFilters',
    props: [
        'filters',
    ],
    emits: [
        'update:filters'
    ],
    computed: {
        audience() {
            return collect(this.$store.state.webmaster.audience).all();
        },
        filterByCountryOptions() {
            return this.convertArrayToOptions(
                collect(this.audience)
                    .pluck('geo')
                    .filter(i => i !== null)
                    .all()
            );
        },
        filterByOfferOptions() {
            return collect(this.audience)
                .map(audience => {
                    return {
                        id: audience.offer.id,
                        name: audience.offer.name,
                    };
                })
                .unique(item => item.id)
                .all();
        },
        isResetFiltersVisible() {
            return this.filters.country.length > 0
                || this.filters.offer.length > 0
                || this.filters.byUtmContent !== ''
                || this.filters.byUtmMedium !== ''
                || this.filters.byUtmCampaign !== ''
                || this.filters.byUtmTerm !== '';
        },
    },
    methods: {
        convertArrayToOptions(array) {
            return collect(array)
                .flatten()
                .unique()
                .map(item => {
                    return {id: item, name: item};
                })
                .all();
        },
        resetFilters() {
            this.$emit('update:filters', {
                ...this.filters,
                dateRange: {startDate: (new Date), endDate: (new Date)},
                byUtmContent: '',
                byUtmMedium: '',
                byUtmCampaign: '',
                byUtmTerm: '',
                country: [],
                offer: [],
            });
        },
        setDateRange(dateRange) {
            this.$emit('update:filters', {...this.filters, dateRange: {startDate: dateRange.startDate, endDate: dateRange.endDate}});
        },
    },
};
</script>

<style lang="scss">

@import '../../../scss/utils/mixins.scss';
@import '../../../scss/utils/variables.scss';

.StatSessionFilters {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    margin-top: 15px;
    & > div {
        flex: none;
        margin-right: 15px;
        margin-bottom: 40px;
        @include break(md) {
            width: calc(50% - 15px) !important;
            &:nth(2n) {
                margin-right: 0;
            }
        }
        @include break(lg) {
            width: calc(33.33% - 15px) !important;
            &:nth(3n) {
                margin-right: 0;
            }
        }
        @include break(xl) {
            width: calc(25% - 15px) !important;
            &:nth(4n) {
                margin-right: 0;
            }
        }

    }
}
</style>