<template>
    <div class="StatAudience">

        <Card>

            <StatAudienceFilters
                v-model:filters="filters"
            ></StatAudienceFilters>

            <Loading v-show="is_loading"></Loading>

            <div class="StatAudience__table"  v-show="!is_loading">
                <div class="StatAudience__table__wrapper">
                    <StatAudienceChart :gender="male" :genderData="audienceMenData"></StatAudienceChart>
                    <StatAudienceChart :gender="female" :genderData="audienceWomenData"></StatAudienceChart>
                    <StatAudienceGeoChart :geo="geo" :geoData="audienceGeoData" v-model:filters="filters"></StatAudienceGeoChart>
                    <StatAudienceGeoChart :geo="region" :geoData="audienceRegionData"></StatAudienceGeoChart>
                </div>
            </div>

        </Card>

    </div>
</template>

<script>

import StatAudienceFilters from './StatAudienceFilters.vue';
import StatAudienceChart from './StatAudienceChart.vue';
import StatAudienceGeoChart from './StatAudienceGeoChart.vue'

export default {
    name: 'StatAudience',
    components: {
        StatAudienceFilters,
        StatAudienceChart,
        StatAudienceGeoChart
    },
    props: [],
    data() {
        return {
            male: 'male',
            female: 'female',
            geo: 'geo',
            region: 'region',
            is_loading: true,
            filters: {
                dateRange: {
                    startDate: (new Date),
                    endDate: (new Date)
                },
                country: [],
                offer: [],
                byUtmContent: '',
                byUtmMedium: '',
                byUtmCampaign: '',
                byUtmTerm: '',
            },
        };
    },
    computed: {
        audience() {
            return collect(this.$store.state.webmaster.audience).where('geo', '!==', null).where('client_gender', '!==', null).where('client_age', '!==', null).all();
        },
        audienceMenData(){
            return collect(this.audienceFiltered).where('client_gender', 'male')
        },
        audienceWomenData(){
            return collect(this.audienceFiltered).where('client_gender', 'female')
        },
        audienceGeoData(){
            return collect(this.audienceFiltered).where('geo')
        },
        audienceRegionData(){
            if(this.filters.country.length > 0){
                return collect(this.audienceFiltered).where('region', '!==', null)
            }
            return [];            
        },
        // фильтрации
        audienceFiltered() {
          return this.audience.filter(audienceItem => {
            let pass = true;

                // фильтр по geo
                if (pass && this.filters.country.length > 0) {
                    pass = false;
                    let geoTerms = collect(this.filters.country)
                        .flatten()
                        .unique()
                        .all()
                        .join('|');
                    if (geoTerms.indexOf(audienceItem.geo) !== -1) pass = true;
                }

                // фильтр по офферу
                if (pass && this.filters.offer.length > 0) {
                    pass = false;
                    let terms = collect(this.filters.offer)
                        .pluck('id')
                        .flatten()
                        .unique()
                        .all()
                        .join('|');
                    if (terms.indexOf(audienceItem.offer.id) !== -1) pass = true;
                }

                // фильтр по UTM Content
                if (pass && this.filters.byUtmContent.length > 0) {
                    let query = this.filters.byUtmContent.toLowerCase()
                    let string = audienceItem.utm.content == null ? '' : audienceItem.utm.content
                    pass = string.toLowerCase().indexOf(query) >= 0
                }

                // фильтр по UTM Medium
                if (pass && this.filters.byUtmMedium.length > 0) {
                    let query = this.filters.byUtmMedium.toLowerCase()
                    let string = audienceItem.utm.medium === null ? '' : audienceItem.utm.medium
                    pass = string.toLowerCase().indexOf(query) >= 0
                }

                // фильтр по UTM Campaign
                if (pass && this.filters.byUtmCampaign.length > 0){
                    let query = this.filters.byUtmCampaign.toLowerCase()
                    let string = audienceItem.utm.campaign === null ? '' : audienceItem.utm.campaign
                    pass = string.toLowerCase().indexOf(query) >= 0
                }

                // фильтр по UTM Term
                if (pass && this.filters.byUtmTerm.length > 0) {
                    let query = this.filters.byUtmTerm.toLowerCase();
                    let string = audienceItem.utm.term === null ? '' : audienceItem.utm.term;
                    pass = string.toLowerCase().indexOf(query) >= 0;
                }
                return pass;
            });
          },
        serializedFilters() {
            let filters = {
                from: this.filters.dateRange.startDate.getFormattedDate() + ' 00:00:00',
                to: this.filters.dateRange.endDate.getFormattedDate() + ' 23:59:59',
                limit: 5000,
            };

            return (new URLSearchParams(filters)).toString();
        },

    },
    methods: {
        getStatAudience() {
            this.is_loading = true;
            this.$store.dispatch('webmaster/getStatAudience', this.serializedFilters)
                .then(() => {
                    this.is_loading = false;
                });
        },
    },
    watch: {
        filters: {
            deep: true,
            handler(val, oldVal) {
                this.getStatAudience();
            }
        }
    },
    beforeMount(){
        this.getStatAudience();
    },
    beforeUnmount() {
        this.$store.commit('webmaster/clean', ['audience'])
    }
};
</script>

<style lang="scss">

@import '../../../scss/utils/mixins.scss';
@import '../../../scss/utils/variables.scss';

.StatAudience {
    width: 100%;
    margin-top: 90px;
    @include break(md) {
        margin-top: 0;
    }
    &__table {
        margin: -30px;
        &__wrapper{
            display: grid;
            gap:10px;
            grid-template-columns: 1fr;
            @include minWidth(1050) {
                grid-template-columns: 1fr 1fr;
            }
        }
    }
}
</style>